export const LangDe = "de-DE";
export const LangEn = "en-GB";
export const LangUnset = "unset";
export const I18NLanguages = [LangEn, LangDe, LangUnset];

type I18NLanguage = typeof LangEn | typeof LangDe | typeof LangUnset;
export default I18NLanguage;

export function validateLanguage(
  lang: string | null | undefined
): I18NLanguage | null {
  if (typeof lang === "undefined" || lang === null) {
    return null;
  }

  if (I18NLanguages.includes(lang)) return lang as I18NLanguage;

  if (!lang.includes("-")) return null;

  const country = lang.toLowerCase().split("-")[1];
  if (country === "en") return LangEn;
  if (country === "de") return LangDe;

  return null;
}
