import { createI18n } from "vue-i18n";
import en from "./locales/en-GB.json";
import de from "./locales/de-DE.json";

type MessageSchema = typeof en | typeof de;

const i18n = createI18n<[MessageSchema], "en-GB" | "de-DE">({
  locale: "en-GB",
  fallbackLocale: "de-DE",

  legacy: true,
  globalInjection: true,
  messages: { "en-GB": en, "de-DE": de },
  pluralRules: {
    "en-GB": customRule,
    "de-DE": customRule,
  },
  datetimeFormats: {
    "en-GB": {
      short: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      },
      long: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      },
      full: {
        weekday: "long",
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      },
    },
    "de-DE": {
      short: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      },
      long: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      },
      full: {
        weekday: "long",
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      },
    },
  },
  numberFormats: {
    "en-GB": {
      percentage: {
        style: "percent",
      },
      currency: {
        style: "currency",
        currency: "EUR",
        currencyDisplay: "symbol",
      },
    },
    "de-DE": {
      percentage: {
        style: "percent",
      },
      currency: {
        style: "currency",
        currency: "EUR",
        currencyDisplay: "symbol",
      },
    },
  },
});

function customRule(nrOfItems: number): number {
  return nrOfItems === 0 ? 0 : nrOfItems === 1 ? 1 : 2;
}

export default i18n;
