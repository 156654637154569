import type { LoginSuccessDto } from "@/dto/LoginSuccessDto";
import ApiClient from "@/api/infrastructure/ApiClient";
import type LoginDto from "@/dto/LoginDto";
import type AccountDto from "@/dto/AccountDto";
import type { RequestPasswordResetDto } from "@/dto/RequestPasswordResetDto";
import type { UpdatePasswordDto } from "@/dto/UpdatePasswordDto";
import type { ResetPasswordDto } from "@/dto/ResetPasswordDto";

export default {
  async login(dto: LoginDto): Promise<LoginSuccessDto> {
    const response = await ApiClient.postJson<LoginDto, LoginSuccessDto>(
      "authentication/login",
      dto
    );

    return response.data;
  },

  async updatePassword(dto: UpdatePasswordDto): Promise<void> {
    await ApiClient.post("authentication/updatePassword", dto);
  },

  async requestPasswordReset(dto: RequestPasswordResetDto): Promise<void> {
    await ApiClient.post<RequestPasswordResetDto>(
      "authentication/requestPasswordReset",
      dto
    );
  },

  async resetPassword(dto: ResetPasswordDto): Promise<void> {
    await ApiClient.post("authentication/resetPassword", dto);
  },

  async validate(): Promise<boolean> {
    try {
      const response = await ApiClient.get("authentication/validate");

      return response.ok;
    } catch (e) {
      return false;
    }
  },

  async self(): Promise<AccountDto> {
    const response = await ApiClient.get("authentication/me");

    return (await response.original.json()) as AccountDto;
  },
};
