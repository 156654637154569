import type { SearchResultsDto } from "@/dto/SearchResultsDto";
import ApiClient from "@/api/infrastructure/ApiClient";
import type { QueryParams } from "@/api/infrastructure/ApiClient";
import type { SearchFilters } from "@/dto/SearchFilters";

export default {
  async query(
    query: string,
    page: number,
    hitsPerPage: number,
    filters: SearchFilters
  ): Promise<SearchResultsDto> {
    const params: QueryParams = {
      q: query,
      page: page.toString(),
      hitsPerPage: hitsPerPage.toString(),
    };

    const result = await ApiClient.postJson<SearchFilters, SearchResultsDto>(
      "search/query",
      filters,
      params
    );

    return result.data;
  },
  async selectableFederalStatesIds(): Promise<number[]> {
    const result = await ApiClient.getJson<number[]>(
      "search/selectableFederalStates"
    );

    return result.data;
  },

  async selectableOrgaTypesIds(): Promise<number[]> {
    const result = await ApiClient.getJson<number[]>(
      "search/selectableOrgaTypes"
    );

    return result.data;
  },
  async selectableSegmentIds(): Promise<number[]> {
    const result = await ApiClient.getJson<number[]>(
      "search/selectableSegments"
    );

    return result.data;
  },

  async selectableCoopIds(): Promise<number[]> {
    const result = await ApiClient.getJson<number[]>("search/selectableCoops");

    return result.data;
  },

  async selectableCertificates(): Promise<number[]> {
    const result = await ApiClient.getJson<number[]>(
      "search/selectableCertificates"
    );

    return result.data;
  },
};
