import * as CookieConsent from "vanilla-cookieconsent";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import type { App, Plugin } from "vue";
import pluginConfig from "./config";

export type CookieValue = CookieConsent.CookieValue;
export type CookieConsentConfig = CookieConsent.CookieConsentConfig;

export const useCookieConsent = () => CookieConsent;

export default {
  install: (app: App) => {
    app.config.globalProperties.$cc = useCookieConsent();
    app.config.globalProperties.$cc.run(pluginConfig);
  },
} as Plugin;
