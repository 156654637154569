import Cookies from "js-cookie";
import { v4 as uuidv4, NIL } from "uuid";

export const sessionIdCookieName = "ksi";

export default {
  init: () => {
    const sessionId = Cookies.get(sessionIdCookieName);
    if (!sessionId) {
      const uuid = uuidv4();

      Cookies.set(sessionIdCookieName, uuid);
    }
  },

  getId: () => {
    return Cookies.get(sessionIdCookieName) ?? NIL;
  },
};
