import ApiClient from "@/api/infrastructure/ApiClient";
import type { LoginSuccessDto } from "@/dto/LoginSuccessDto";
import type { ExchangeRegistrationTokenDto } from "@/dto/ExchangeRegistrationTokenDto";
import type { RegisterRequestDto } from "@/dto/RegisterRequestDto";

export default {
  validateRegistrationToken: async (
    accountId: number,
    token: string
  ): Promise<boolean> => {
    const result = await ApiClient.get(
      "Registration/ValidateRegistrationToken",
      { token, accountId: accountId.toString() },
      false
    );

    return result.ok;
  },

  exchangeRegistrationToken: async (
    dto: ExchangeRegistrationTokenDto
  ): Promise<LoginSuccessDto> => {
    const response = await ApiClient.postJson<
      ExchangeRegistrationTokenDto,
      LoginSuccessDto
    >("Registration/ExchangeRegistrationToken", dto);

    return response.data;
  },

  requestRegistration: async (dto: RegisterRequestDto): Promise<boolean> => {
    const result = await ApiClient.post<RegisterRequestDto>(
      "Registration/RequestRegistration",
      dto
    );

    return result.ok;
  },
};
