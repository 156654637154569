import type MapPinDto from "@/dto/MapPinDto";
import ApiClient from "@/api/infrastructure/ApiClient";

export default {
  async mapPins(): Promise<MapPinDto[]> {
    const response = await ApiClient.getJson<MapPinDto[]>("home/mapPins");

    return response.data;
  },
};
