enum Role {
  Unset = 12,
  Actor = 13,
  EditorExternal = 14,
  EditorInternal = 15,
  Admin = 16,
}

export default Role;

export function canEditAnyEntry(role: Role | null): boolean {
  return (
    role == Role.Admin ||
    role == Role.EditorInternal ||
    role == Role.EditorExternal
  );
}
