<script setup lang="ts">
import KaraButton from "@/components/KaraButton.vue";
import { useRouter } from "vue-router";
import KaraCard from "@/components/KaraCard.vue";

const router = useRouter();

function openRegistration() {
  router.push({ name: "register" });
}
</script>

<template>
  <kara-card class="registration-notice">
    <template #header>
      <h4>{{ $t("registration-notice-title") }}</h4>
    </template>
    <p class="notice">{{ $t("registration-notice-text") }}</p>
    <div class="registration-button-wrapper">
      <kara-button
        class="registration-button mt-2"
        variant="accented"
        @click="openRegistration"
      >
        {{ $t("registration-notice-to-registration") }}
      </kara-button>
    </div>
  </kara-card>
</template>

<style scoped lang="scss">
.registration-notice {
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 1rem;
  font-size: 1em;

  width: 100%;

  color: var(--white);

  border: var(--color-accent) 1px solid;
  background: rgba(9, 13, 24, 0.6);

  .notice {
    margin: 0;
  }

  .registration-button-wrapper {
    display: flex;
    justify-content: center;

    .registration-button {
      width: 60%;
    }
  }
}
</style>
