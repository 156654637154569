import type { CookieConsentConfig, CookieValue } from "@/cookie-consent/index";
import session, { sessionIdCookieName } from "@/helpers/session";

export default {
  root: "#app",

  onConsent: function (v: { cookie: CookieValue }) {
    if (v.cookie.categories.includes("analytics")) {
      session.init();
    }
  },

  onChange: function (v: { cookie: CookieValue }) {
    if (v.cookie.categories.includes("analytics")) {
      session.init();
    }
  },

  revision: 1,
  autoClearCookies: true,

  categories: {
    necessary: {
      enabled: true, // this category is enabled by default
      readOnly: true, // this category cannot be disabled
    },
    analytics: {
      enabled: false,
      readOnly: false,
      autoClear: {
        cookies: [
          {
            name: sessionIdCookieName,
          },
        ],
      },
    },
  },

  language: {
    default: "de",
    autoDetect: "browser",
    translations: {
      en: {
        consentModal: {
          title: "🍪 Consent to the use of cookies",
          description:
            'We use cookies to optimize this website and display it to you. We would also like to show you content that is as relevant as possible. We also use technologies to analyze or track your browsing behavior. You can reject cookies under <button type="button" data-cc="c-settings" class="cc-link">preferences</button>, or use that page to specify which cookies we are allowed to set.<br><br>Both the consent according to article 25 section 1 TTDSG to set and/or read the cookies, as well as the consent according to article 6 section 1 letter a DSGVO to the further use of these cookies by us or our service providers is <em>voluntary</em>. If you change your mind at a later date, you can change these settings at any time for the future.<br><br>If you are at least 16 years old, you can consent to the use of all cookies by pressing the button "Agree to all cookies".',
          acceptAllBtn: "Consent to All Cookies",
          acceptNecessaryBtn: "Reject Cookies",
          showPreferencesBtn: "Preferences",
        },
        preferencesModal: {
          title: "Cookie Preferences raumfahrtakteure.de",
          acceptAllBtn: "Consent to All Cookies",
          acceptNecessaryBtn: "Reject Cookies",
          savePreferencesBtn: "Save Preferences",
          closeIconLabel: "Close",
          sections: [
            {
              title: "Cookie Usage",
              description:
                'We use cookies to optimize this website and display it to you <strong>(Strictly Necessary Cookies)</strong>. We would also like to show you content that is as relevant as possible. We also use technologies to analyze or track your browsing behavior. These are optional <strong>(Performance and Analytics Cookies)</strong>, and are only set if you consent to them.<br><br>For more details regarding cookies and other sensitive data, please read our <a href="https://german-space-actors.com/privacy-policy" class="cc-link">privacy policy</a>.',
            },
            {
              title: "Strictly Necessary Cookies",
              description:
                "These cookies are essential for the proper functioning of the website.",
              linkedCategory: "necessary",
              cookieTable: {
                headers: {
                  name: "Name",
                  expiration: "Expiration",
                  description: "Description",
                  location: "Location",
                },
                body: [
                  {
                    name: "token",
                    expiration: "Session",
                    description: "This cookie stores login information.",
                    location: "Local Storage",
                  },
                  {
                    name: "preferred-language",
                    expiration: "Session",
                    description: "This cookie stores your preferred language.",
                    location: "Local Storage",
                  },
                  {
                    name: "cc_cookie",
                    expiration: "6 Months",
                    description:
                      "This cookie stores your cookie consent preferences.",
                    location: "Cookies",
                  },
                ],
              },
            },
            {
              title: "Performance and Analytics Cookies",
              description:
                "Performance and Analytics Cookies are optional cookies and help us improve your experience.",
              linkedCategory: "analytics",
              cookieTable: {
                headers: {
                  name: "Name",
                  expiration: "Expiration",
                  description: "Description",
                  location: "Location",
                },
                body: [
                  {
                    name: sessionIdCookieName,
                    expiration: "Session",
                    description:
                      "This cookie helps us analyze how our website is used",
                    location: "Cookies",
                  },
                ],
              },
            },
            {
              title: "More information",
              description:
                'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="/contact">contact us</a>.',
            },
          ],
        },
      },
      de: {
        consentModal: {
          title: "🍪 Einwilligung in den Einsatz von Cookies",
          description:
            'Wir nutzen Cookies, um diese Website optimieren und Ihnen anzeigen zu können. Außerdem möchten wir Ihnen gerne möglichst relevante Inhalte anzeigen. Wir nutzen darüber hinaus Technologien, um Ihr Surfverhalten zu analysieren oder zu tracken. Sie können unter <button type="button" data-cc="c-settings" class="cc-link">weitere Einstellungen</button> die Cookies ablehnen oder dort festlegen, welche Cookies wir setzen dürfen.<br><br>Sowohl die Einwilligung nach § 25 Abs. 1 TTDSG zum Setzen und/oder Auslesen der Cookies, sowie die Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO zur weiteren Verwendung dieser Cookies durch uns oder unsere Dienstleister ist <em>freiwillig</em>. Wenn Sie zu einem späteren Zeitpunkt Ihre Meinung ändern, können Sie diese Einstellungen jederzeit für die Zukunft ändern.<br><br>Wenn Sie mindestens 16 Jahre alt sind, können Sie mit der Betätigung des Buttons „Allen Cookies zustimmen“ in die Nutzung sämtlicher Cookies einwilligen.',
          acceptAllBtn: "Allen Cookies zustimmen",
          acceptNecessaryBtn: "Cookies ablehnen",
          showPreferencesBtn: "Einstellungen",
        },
        preferencesModal: {
          title: "Cookie Einstellungen raumfahrtakteure.de",
          acceptAllBtn: "Allen Cookies zustimmen",
          acceptNecessaryBtn: "Cookies ablehnen",
          savePreferencesBtn: "Einstellungen speichern",
          closeIconLabel: "Schließen",
          sections: [
            {
              title: "Cookie-Nutzung",
              description:
                'Wir nutzen Cookies, um diese Website optimieren und Ihnen anzeigen zu können <strong>(Streng erforderliche Cookies)</strong>. Außerdem möchten wir Ihnen gerne möglichst relevante Inhalte anzeigen. Wir nutzen darüber hinaus Technologien, um Ihr Surfverhalten zu analysieren oder zu tracken. Diese sind optional <strong>(Performance- und Analysecookies)</strong>, und werden nur gesetzt, wenn Sie dem zustimmen.<br><br>Zu weiteren Einzelheiten bezüglich Cookies und anderer sensibler Daten lesen Sie bitte unsere <a href="https://german-space-actors.com/privacy-policy" class="cc-link">Datenschutzerklärung</a>.',
            },
            {
              title: "Streng erforderliche Cookies",
              description:
                "Diese Cookies sind zur korrekten Funktion der Website erforderlich.",
              linkedCategory: "necessary",
              cookieTable: {
                headers: {
                  name: "Name",
                  expiration: "Ablauf",
                  description: "Beschreibung",
                  location: "Speicherort",
                },
                body: [
                  {
                    name: "token",
                    expiration: "Sitzung",
                    description: "Dieses Cookie speichert Logininformationen.",
                    location: "Local Storage",
                  },
                  {
                    name: "preferred-language",
                    expiration: "Sitzung",
                    description:
                      "Dieses Cookie speichert die bevorzugte Anzeigesprache.",
                    location: "Local Storage",
                  },
                  {
                    name: "cc_cookie",
                    expiration: "6 Monate",
                    description:
                      "Dieses Cookie speichert Ihre Cookie-Zustimmungseinstellungen.",
                    location: "Cookies",
                  },
                ],
              },
            },
            {
              title: "Leistungs- und Analyse-Cookies",
              description:
                "Leistungs- und Analyse-Cookies sind optional und helfen uns die Benutzererfahrung zu verbessern.",
              linkedCategory: "analytics",
              cookieTable: {
                headers: {
                  name: "Name",
                  expiration: "Ablauf",
                  description: "Beschreibung",
                  location: "Speicherort",
                },
                body: [
                  {
                    name: sessionIdCookieName,
                    expiration: "Sitzung",
                    description:
                      "Dieses Cookie hilft uns zu analysieren, wie unsere Website verwendet wird",
                    location: "Cookies",
                  },
                ],
              },
            },
            {
              title: "Weitere Informationen",
              description:
                'Zu Anfragen bezüglich unserer Cookie-Richtlinien und Ihrer Auswahlmöglichkeiten <a class="cc-link" href="/contact">kontaktieren Sie uns</a> gerne.',
            },
          ],
        },
      },
    },
  },
} as CookieConsentConfig;
