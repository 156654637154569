import { createRouter, createWebHistory } from "vue-router";
import useAuthStore from "@/stores/auth";
import toasts from "@/toasts";
import routes from "./routes";
import Cookies from "js-cookie";
import api from "@/api";
import session from "@/helpers/session";
import Role from "@/stores/interfaces/Role";

const previewLoginEnabled = import.meta.env.VITE_PREVIEW_ENABLED === "true";
const previewPasswordHash = import.meta.env.VITE_PREVIEW_PASSWORD_HASH;

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    }

    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
  routes,
});

router.beforeEach(async (to, from) => {
  if (previewLoginEnabled && to.name !== "preview-login") {
    const previewToken = Cookies.get("preview-token") ?? null;
    if (previewToken === null) {
      return { name: "preview-login", query: { return: to.fullPath } };
    }

    if (previewToken !== previewPasswordHash) {
      return { name: "preview-login", query: { return: to.fullPath } };
    }
  } else if (!previewLoginEnabled && to.name === "preview-login") {
    if (typeof to.query["return"] === "string") {
      const decoded = decodeURIComponent(to.query["return"]);
      if (decoded.startsWith("/")) {
        return decoded;
      }
    }

    return { name: "home" };
  }

  if (to.name === "not-found" && to.path !== "/not-found") {
    return { path: "not-found" };
  }

  const auth = useAuthStore();

  const user = await auth.fetchSelf();
  const role = user?.roleId ?? null;

  if (to.name === "welcome" && auth.isSignedIn) {
    if (auth.user.roleId === Role.Actor) {
      // don't catch admins and reviewers which don't have any entries
      const accountID = parseInt(to.query.aid?.toString() ?? "");
      if (!isNaN(accountID) && accountID === auth.user.id) {
        if (auth.user.hasDraftEntry) {
          return {
            name: "entry-editor",
            params: { entrySlug: auth.user.draftSlug },
          };
        } else if (auth.user.hasActiveEntry) {
          return {
            name: "entry-view",
            params: { entrySlug: auth.user.activeSlug },
          };
        }
      }
    }

    await auth.signOut();

    // cause a redirect to reload the page
    return to;
  }

  if (to.meta.onlyGuest && auth.isSignedIn) {
    if (to.name === "login" && typeof to.query["return"] === "string") {
      const decoded = decodeURIComponent(to.query["return"]);
      if (decoded.startsWith("/")) {
        return decoded;
      }
    }

    toasts.onlyGuestsAllowed();

    return from;
  }

  if (to.meta.minimumRole !== null) {
    if (!auth.isSignedIn || role === null) {
      // don't show a toast when we just logged out
      if (from.name !== "logout") {
        toasts.authenticationRequired();
      }

      return {
        name: "login",
        query: { return: to.fullPath },
      };
    }

    if (role < to.meta.minimumRole) {
      if (from.name === "login") {
        // can't redirect back to login if access was denied, so go home
        return { name: "home" };
      }

      toasts.accessDenied();

      return from;
    }
  }
});

router.afterEach((to, from, failure) => {
  if (failure) {
    return;
  }

  setTimeout(async () => {
    await api.analytics.view({
      sessionId: session.getId(),
      path: to.fullPath,
    });
  }, 0);
});

export default router;
