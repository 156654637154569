<script setup lang="ts">
import { useSiteSettings } from "@/stores/siteSettings";
import { ref, watch } from "vue";
import useAuthStore from "@/stores/auth";
import { LangDe, LangEn } from "@/stores/interfaces/I18NLanguage";

const auth = useAuthStore();
const settings = useSiteSettings();

const selectedLanguage = ref(settings.state.preferredLanguage);

watch(selectedLanguage, async (chosenLanguage) => {
  if (settings.state.preferredLanguage !== chosenLanguage) {
    await settings.setLanguage(chosenLanguage, auth.isSignedIn);
  }
});

watch(settings.state, (s) => {
  if (s.preferredLanguage !== selectedLanguage.value) {
    selectedLanguage.value = s.preferredLanguage;
  }
});
</script>

<template>
  <form>
    <label for="lang-de">
      <input
        type="radio"
        id="lang-de"
        name="language"
        :value="LangDe"
        v-model="selectedLanguage"
      />
      <img src="@/assets/flags/de_de.png" alt="German flag" />
    </label>
    <label for="lang-en">
      <input
        type="radio"
        id="lang-en"
        name="language"
        :value="LangEn"
        v-model="selectedLanguage"
      />
      <img src="@/assets/flags/en_gb.png" alt="Union flag" />
    </label>
  </form>
</template>

<style lang="scss" scoped>
// TODO: round outline edges, handle focus

form {
  display: flex;
  flex-direction: row;
}

label {
  cursor: pointer;

  input[type="radio"] {
    appearance: none;

    &:focus {
      outline: none;

      &:checked + img {
        outline-width: 4px;
      }
    }

    &:checked + img {
      outline-offset: 2px;
      outline: solid 2px var(--color-outline);
    }

    & + img {
      height: 16px;
      width: auto;

      margin-left: 0.5rem;
      border-radius: 2px;
    }
  }
}
</style>
