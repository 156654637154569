<script setup lang="ts">
import { computed, ref } from "vue";
import type MapPinDto from "@/dto/MapPinDto";
import KaraMapPinInfo from "@/components/KaraMapPinInfo.vue";
import type { MapType } from "@/components/models/MapType";

export interface Props {
  zoom?: number;
  latitude?: number;
  longitude?: number;
  pinCenter?: boolean;
  pins?: MapPinDto[];
  type: MapType;
}

const props = withDefaults(defineProps<Props>(), {
  zoom: 6,
  latitude: 51.16077,
  longitude: 10.448851,
  pinCenter: false,
});

const emit = defineEmits([
  "update:zoom",
  "update:latitude",
  "update:longitude",
]);

const center = computed({
  get: () => ({ lat: props.latitude, lng: props.longitude }),
  set: (center) => {
    emit("update:latitude", center.lat);
    emit("update:longitude", center.lng);
  },
});

const openInfoWindowSlug = ref<string | null>(null);

function openMarker(pin: MapPinDto | null) {
  if (pin === null) {
    openInfoWindowSlug.value = null;

    return;
  }

  openInfoWindowSlug.value = pin.slug;
}
</script>

<template>
  <g-map-map
    :center="center"
    :zoom="zoom ?? 7"
    class="kara-map"
    :options="{
      streetViewControl: false,
      rotateControl: false,
      version: 'weekly',
      mapTypeId: type,
    }"
    @click="openMarker(null)"
  >
    <g-map-marker v-if="pinCenter" :position="center" />

    <template v-if="pins">
      <g-map-marker
        v-for="(pin, idx) in pins"
        :key="idx"
        :position="{ lat: pin.location.latitude, lng: pin.location.longitude }"
        clickable
        @click="openMarker(pin)"
      >
        <g-map-info-window
          closeclick
          @closeclick="openMarker(null)"
          :opened="openInfoWindowSlug === pin.slug"
        >
          <kara-map-pin-info :pin="pin" />
        </g-map-info-window>
      </g-map-marker>
    </template>
  </g-map-map>
</template>

<style scoped lang="scss">
.kara-map {
  width: 100%;
  height: 100%;
}
</style>
