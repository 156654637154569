import { defineStore } from "pinia";
import type SiteSettingsStoreState from "@/stores/interfaces/SiteSettingsStoreState";
import { computed, reactive } from "vue";
import api from "@/api";
import { useI18n } from "vue-i18n";
import { useCookieConsent } from "@/cookie-consent";
import { validateLanguage, LangDe } from "@/stores/interfaces/I18NLanguage";
import type I18NLanguage from "@/stores/interfaces/I18NLanguage";

const cc = useCookieConsent();

export const useSiteSettings = defineStore("site-settings", () => {
  const languageStorageKey = "preferred-language";
  let localPreferredLanguage: I18NLanguage | null = validateLanguage(
    localStorage.getItem(languageStorageKey)
  );

  if (localPreferredLanguage === null) {
    for (const lang of navigator.languages) {
      const validated = validateLanguage(lang);
      if (validated !== null) {
        localPreferredLanguage = validated;

        break;
      }
    }
  }

  // global language fallback to de-DE
  localPreferredLanguage ??= LangDe;

  const i18n = useI18n();

  i18n.locale.value = localPreferredLanguage;

  cc.setLanguage(localPreferredLanguage, true);

  const state = reactive<SiteSettingsStoreState>({
    preferredLanguage: localPreferredLanguage,
    preferredMode: "system",
  });

  const preferredLanguage = computed(() => state.preferredLanguage);
  const preferredMode = computed(() => state.preferredMode);

  async function setLanguage(
    preferred: string,
    updateRemote: boolean = true
  ): Promise<void> {
    const validated = validateLanguage(preferred);
    if (validated === null) {
      return;
    }

    i18n.locale.value = validated;
    state.preferredLanguage = validated;

    await cc.setLanguage(validated, true);

    localStorage.setItem(languageStorageKey, validated);

    if (updateRemote) {
      await api.language.set(validated);
    }
  }

  // const darkModeChangeTrigger = ref(false);
  // const darkModeMatcher = Object.hasOwnProperty.call(window, "matchMedia")
  //   ? window.matchMedia("(prefers-color-scheme: dark)")
  //   : null;
  //
  // // change the trigger value if the matches notices change in preferred scheme
  // darkModeMatcher?.addListener(
  //   () => (darkModeChangeTrigger.value = !darkModeChangeTrigger.value)
  // );
  //
  // const darkMode = computed(() => {
  //   if (state.preferredMode == "system") {
  //     // update the computed property if the trigger changes
  //     darkModeChangeTrigger.value;
  //
  //     return darkModeMatcher?.matches ?? false;
  //   } else {
  //     return state.preferredMode == "dark";
  //   }
  // });
  //
  // watch(darkMode, () => {
  //   document.body.classList.remove("dark");
  //   document.body.classList.remove("light");
  //   document.body.classList.remove("system");
  //
  //   document.body.classList.add(state.preferredMode);
  // });

  return {
    state,
    preferredLanguage,
    preferredMode,
    setLanguage,
  };
});
