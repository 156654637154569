<script setup lang="ts">
import KaraButton from "@/components/KaraButton.vue";
import KaraIcon from "@/components/KaraIcon.vue";

export interface Props {
  collapsible?: boolean;
  open?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  collapsible: false,
  open: false,
});
const emits = defineEmits(["update:open"]);

function toggleOpen() {
  emits("update:open", !props.open);
}
</script>

<template>
  <div class="card-wrapper">
    <kara-button
      v-if="collapsible"
      class="header"
      variant="link"
      flat
      @click="toggleOpen"
    >
      <slot name="header"></slot>
      <kara-icon v-if="!open" name="angle-double-down" />
      <kara-icon v-else name="angle-double-up" />
    </kara-button>
    <slot v-else name="header"></slot>
    <div
      v-if="collapsible"
      :class="['card-body', open ? 'mt-2' : '']"
      v-show="open"
    >
      <slot></slot>
    </div>
    <slot v-if="!collapsible"></slot>
  </div>
</template>

<style scoped lang="scss">
.card-wrapper {
  padding: calc(var(--utilities-base) * 3);
  border-radius: var(--utilities-base);
  background: var(--color-over-paper);
  box-shadow: var(--shadow-small);

  transition: box-shadow 0.2s ease-out;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-decoration: none;
  overflow: visible;
}
</style>
