import type { LabelDto } from "@/dto/LabelDto";
import ApiClient from "@/api/infrastructure/ApiClient";
import type { RangeDto } from "@/dto/RangeDto";
import type { CategoryDto } from "@/dto/CategoryDto";

export default {
  async states(): Promise<LabelDto[]> {
    const response = await ApiClient.getJson<LabelDto[]>("lists/States");

    return response.data;
  },

  async budgetRanges(): Promise<RangeDto[]> {
    const response = await ApiClient.getJson<RangeDto[]>("lists/BudgetRanges");

    return response.data;
  },

  async numberOfEmployeeRanges(): Promise<RangeDto[]> {
    const response = await ApiClient.getJson<RangeDto[]>(
      "lists/NumberOfEmployeeRanges"
    );

    return response.data;
  },

  async targetedCooperations(): Promise<CategoryDto[]> {
    const response = await ApiClient.getJson<CategoryDto[]>(
      "lists/TargetedCooperations"
    );

    return response.data;
  },

  async organisationTypes(): Promise<CategoryDto[]> {
    const response = await ApiClient.getJson<CategoryDto[]>(
      "lists/OrganisationTypes"
    );

    return response.data;
  },

  async segments(): Promise<CategoryDto[]> {
    const response = await ApiClient.getJson<CategoryDto[]>("lists/Segments");

    return response.data;
  },

  async missions(): Promise<CategoryDto[]> {
    const response = await ApiClient.getJson<CategoryDto[]>("lists/Missions");

    return response.data;
  },

  async certificates(): Promise<CategoryDto[]> {
    const response = await ApiClient.getJson<CategoryDto[]>(
      "lists/Certificates"
    );

    return response.data;
  },
};
