<script setup lang="ts">
const mode = import.meta.env.MODE;
const gitsha1 = import.meta.env.VITE_APP_GIT_SHA1;
const gitbranch = import.meta.env.VITE_APP_GIT_BRANCH;

const bannerType =
  typeof mode !== "undefined" ? mode.toLowerCase() : "production";

const bannerText =
  typeof gitsha1 !== "undefined" && typeof gitbranch !== "undefined"
    ? `${gitsha1.substring(0, 7)} (${gitbranch})`
    : bannerType;
</script>

<template>
  <div v-if="bannerType !== 'production'" :class="[bannerType]">
    {{ bannerText }}
  </div>
</template>

<style lang="scss" scoped>
div {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0 0.5em;
  border-radius: 0 0.5em 0 0;
  background: darkred;
  color: white;
  font-weight: bolder;
  font-size: 75%;

  &.staging {
    color: #fff;
    background: #379600;
  }

  &.preview {
    $dark: #000000;
    $stripe: #966100;
    $stripeWidth: 10px;

    color: #fff;
    background: repeating-linear-gradient(
      -45deg,
      $dark,
      $dark $stripeWidth,
      $stripe $stripeWidth,
      $stripe $stripeWidth * 2
    );
  }
}
</style>
