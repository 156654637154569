import authentication from "@/api/authentication";
import language from "@/api/language";
import entries from "@/api/entries";
import lists from "@/api/lists";
import registration from "@/api/registration";
import enquiry from "@/api/enquiry";
import analytics from "@/api/analytics";
import admin from "@/api/admin";
import home from "@/api/home";
import search from "@/api/search";
import internals from "@/api/internals";

export default {
  analytics,
  authentication,
  language,
  entries,
  lists,
  registration,
  enquiry,
  admin,
  home,
  search,
  internals,
};
