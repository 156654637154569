function distinct(value: number, index: number, array: number[]) {
  return array.indexOf(value) === index;
}

function withDistinct(array: number[], element: number): number[] {
  return [...array, element].filter(distinct);
}

function equals(a: number[], b: number[]) {
  if (a.length !== b.length) {
    return false;
  }

  const sortedA = [...a].sort();
  const sortedB = [...b].sort();

  return sortedA.every((v, i) => v === sortedB[i]);
}

export default {
  distinct,
  withDistinct,
  equals,
};
