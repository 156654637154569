import type { LocationQueryValue } from "vue-router";

const routeParsers = {
  parseString: (
    raw: LocationQueryValue | LocationQueryValue[]
  ): string | null => {
    const value = Array.isArray(raw) ? raw[0] : raw;

    if (value === null || value === undefined || value.length === 0)
      return null;

    return value as string;
  },

  parseNumbers: (raw: LocationQueryValue | LocationQueryValue[]): number[] => {
    const value = Array.isArray(raw) ? raw[0] : raw;

    if (value === null || value === undefined || value.length === 0) return [];

    return (value as string)
      .split(",")
      .map((s) => parseInt(s))
      .filter((s) => !isNaN(s));
  },

  parseNumber: (
    raw: LocationQueryValue | LocationQueryValue[]
  ): number | null => {
    const numbers = routeParsers.parseNumbers(raw);

    if (numbers.length !== 1) return null;

    return numbers[0];
  },

  parseRange: (
    raw: LocationQueryValue | LocationQueryValue[]
  ): { min: number | null; max: number | null } => {
    const range = routeParsers.parseString(raw)?.split("-");
    if (range === undefined || range.length !== 2) {
      return { min: null, max: null };
    }
    const min = !isNaN(parseInt(range[0])) ? parseInt(range[0]) : null;
    const max = !isNaN(parseInt(range[1])) ? parseInt(range[1]) : null;

    return { min, max };
  },

  stringifyRange: (min: number | null, max: number | null): string | null => {
    if (min === null && max === null) return null;
    if (min === null) return `-${max}`;
    if (max === null) return `${min}-`;
    return `${min}-${max}`;
  },
};

export default routeParsers;
